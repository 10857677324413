<template>
  <div>
    <ul v-if="addresses.length > 0" class="iq-style4 curva-list p-3 mb-5">
      <li class="p-2 pb-5" v-for="(address, ind) in addresses" :key="ind">
        <b-row>
          <!-- {{ address }} -->
          <b-col lg="3" class="d-flex justify-content-center mb-4" v-if="address.gps">
            <img class="mini-map" :src="address.gps"/></b-col>
          <b-col lg="9" class="mb-2">
            <b-row class="text-left">
              <b-col lg="12">
                <p class="text-initial"><i class="text-warning las la-map-marker-alt mr-2"></i>
                  {{ address.street }} {{address.region ? address.region.name : '' }} {{address.city?address.city.name : ''}}</p>
              </b-col>
              <b-col>
                <p class="text-initial"><i class="text-warning las la-info-circle mr-2"></i>
                  {{ $t('forms.block_no')}} {{address.block_no}} ,  {{ $t('forms.flat_no')}} {{address.flat_no}} , {{ $t('forms.floor_no')}} {{address.floor_no}}
                </p>
              </b-col>
              <b-col lg="12">
                <p class="text-initial"><i class="text-warning las la-phone mr-2"></i>{{address.phone1}}
                  <span v-if="address.phone2">{{` | ${address.phone2}`}}
                            </span></p>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" class="d-flex align-items-start justify-content-around">
            <router-link :to="{name:'addAddress', params:{type: 'edit', oldData: address}}">
            <button class="curva-icon-btn"><i class="las la-pen"></i> Edit</button></router-link>
            <button class="curva-remove-btn" @click="deleteAddress(address.id)"><i class="las la-trash"></i></button>
          </b-col>
        </b-row>
      </li>
    </ul>
    <div class="iq-style4 order-container curva-list" v-else>
      <h5 class="p-2 text-center text-white">{{ $t('profile.noAddress') }}</h5></div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  data () {
    return {
      addresses: []
    }
  },
  methods: {
    getAddress () {
      ProfileServices.getAddress().then(res => {
        console.log(res.data.data)
        this.addresses = res.data.data
      })
    },
    deleteAddress (id) {
      ProfileServices.deleteAddress(id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.getAddress()
      })
    }
  },
  created () {
    this.getAddress()
  },
  mounted () {
    core.index()
  }
}
</script>
